.filter-bar {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-top: 16px;

    .tag-filter {
        min-height: 34px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 2px 14px;
        border-radius: 100px;
        color: var(--tertiaryButton);
        border: solid 2px var(--tertiaryButton);
        cursor: pointer;
        transition: color, border 0.6s ease;

        i {
            font-size: 16px;
            margin-top: -2px;
            margin-right: 6px;
            margin-left: -2px;
            transition: color 0.6s ease;
            z-index: 1;
        }

        .tag-title {
            z-index: 1;
            transition: color 0.6s ease;
        }

        &.selected {
            color: var(--background);
            border: solid 2px var(--tertiaryButton);
        }
    }

    .selected-filter {
        min-height: 34px;
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        padding: 0px 12px;
        border: solid 2px var(--tertiaryButton);
        background-color: var(--tertiaryButton);
    }
}
