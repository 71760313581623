.banner {
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr);
    column-gap: 8px;
    padding: 20px 20px 20px 12px;
    border-radius: 2px;

    .img-container {
        display: flex;
        justify-content: center;

        span,
        i {
            font-size: 20px;
        }
    }

    .banner-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.2;
    }

    &.info {
        background-color: var(--infoBackground);

        .img-container {
            color: var(--info);
        }
    }
}
