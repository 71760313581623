@import '../../../themes/constants.scss';

.other-section {
    position: relative;
    padding: 80px 0 40px 80px;
    overflow: hidden;

    .section-content {
        display: grid;
        width: 100%;
        grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
        grid-template-rows: min-content min-content;
        column-gap: 32px;
        align-items: center;

        .header-emoji {
            position: static;
        }

        .body-text {
            margin-top: 16px;
        }

        .image-wrapper {
            display: flex;
            height: 100%;

            .other-image {
                aspect-ratio: 1;
                width: 100%;
                height: 0;
                min-height: 100%;
                justify-self: center;
            }
        }

        .toggle-wrapper {
            margin: 20px 0;
        }

        .other-cards {
            width: calc(100% + 80px);
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            grid-template-rows: 1fr;
            grid-column: 1 / -1;
            align-items: center;
            margin: 0 -80px;
            overflow: hidden;

            .other-cards-list {
                width: 100%;
                display: flex;
                gap: 20px;
                grid-column: 1 / -1;
                grid-row: 1 / -1;
                padding: 0 40px 16px 80px;
                overflow-x: auto;
                overflow-y: hidden;

                &.articles {
                    .other-card {
                        .other-card-logo {
                            background-color: var(--tertiaryDark);
                        }
                    }
                }
            }

            .other-card {
                width: 400px;
                min-width: 400px;
                height: 240px;
                display: grid;
                grid-template-columns: minmax(0, 100px) minmax(0, 1fr);
                grid-template-rows: 24px 1fr 48px;
                gap: 8px 12px;
                padding: 28px 12px 12px 20px;
                border-radius: 4px;
                background-color: var(--backgroundSecondary);

                .other-card-logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    grid-row: 1 / 4;
                    margin: -28px 8px -12px -20px;
                    background-color: var(--primaryLight);
                    border-radius: 4px 0 0 4px;

                    .other-card-icon {
                        font-size: 48px;
                        color: var(--cardIcon);
                    }
                }

                .other-card-description {
                    margin-bottom: 8px;
                }

                .other-card-button {
                    align-self: flex-end;
                    justify-self: flex-end;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        padding-top: 0;

        .section-content {
            grid-template-columns: minmax(0, 1fr);

            .image-wrapper {
                grid-column: 1 / -1;
                grid-row: 1;
                justify-self: center;

                .other-image {
                    height: auto;
                    margin-right: 80px;
                    max-width: 400px;
                }
            }

            .other-content {
                margin-right: 80px;

                .other-text {
                    grid-column: 1 / -1;
                }
            }
        }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        padding: 20px 0 20px 40px;

        .section-content {
            grid-template-columns: minmax(0, 1fr);

            .image-wrapper {
                .other-image {
                    grid-column: auto;
                    margin-left: -30px;
                    margin-right: 40px;
                    grid-row: 1;
                }
            }

            .other-content {
                margin-right: 40px;

                .other-text {
                    grid-column: 1 / -1;
                }
            }

            .other-cards {
                .other-card {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(4, min-content);
                    width: 300px;
                    min-width: 300px;
                    height: 400px;

                    .other-card-logo {
                        height: 150px;
                        margin: -28px -12px 8px -20px;
                        border-radius: 4px 4px 0 0;
                    }

                    .other-card-button {
                        justify-self: stretch;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1920px) {
        max-height: 875px;

        .section-content {
            .other-image {
                max-height: 550px;
                max-width: 550px;
            }
        }
    }
}
