.skill-popup {
    .popup-header {
        display: grid;
        grid-template-columns: 80px 1fr;
        column-gap: 16px;

        .header-image {
            width: 80px;
            height: 80px;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            aspect-ratio: 1;
        }

        .as-h2 {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            align-self: flex-end;
        }

        .tags {
            display: flex;
            grid-row: 2 / 3;
            grid-column: 2 / 3;
            align-items: center;
            column-gap: 12px;

            .popup-skill-tag {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2px 4px;
                border-radius: 100px;
                background-color: var(--secondaryTinted);

                .card-tag-icon {
                    font-size: 20px;
                    color: var(--textBody);
                    margin-right: 4px;
                }

                .card-tag-label {
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-right: 4px;
                }
            }
        }
    }

    .scrollable-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
        row-gap: 36px;

        .description {
            line-height: 1.4;
        }

        .skill-popup-banner {
            margin: -12px 0;
        }

        .bullets {
            .bullet-list {
                padding: 0 24px;
                margin-top: 12px;

                li {
                    line-height: 1.4;
                    margin-bottom: 6px;
                }
            }
        }

        /* Scroll shadows (cover top, cover bottom, top, bottom) */
        background: linear-gradient(
                    var(--background) 30%,
                    var(--scrollShadowStart)
                )
                center top,
            linear-gradient(var(--scrollShadowStart), var(--background) 70%)
                center bottom,
            radial-gradient(
                    farthest-side at 50% 0,
                    var(--scrollShadowEnd),
                    var(--scrollShadowStart)
                )
                center top,
            radial-gradient(
                    farthest-side at 50% 100%,
                    var(--scrollShadowEnd),
                    var(--scrollShadowStart)
                )
                center bottom;

        background-repeat: no-repeat;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-attachment: local, local, scroll, scroll;
    }
}
