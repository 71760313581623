.skip-link {
    position: absolute;
    left: 50%;
    display: flex;
    color: var(--textBody);
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background-color: var(--actionButtonHover);
    border-radius: 100px;
    box-shadow: 0px 0px 6px 1px var(--boxShadow);
    transition: 0.4s ease;
    transform: translateY(-100px);
    z-index: 2;

    &:focus {
        transform: translateY(20px);
    }
}
