.nav-bar {
    display: grid;
    grid-template-columns: max-content 1fr repeat(4, max-content);
    column-gap: 32px;
    flex-shrink: 0;
    padding: 28px 24px 20px;
    overflow: hidden;

    .nav-bar-title {
        font-weight: 700;
        text-transform: uppercase;
        transform-origin: center;
        transition: 0.4s ease;
        cursor: pointer;
        user-select: none;
    }

    .nav-bar-item {
        position: relative;
        display: flex;
        font-weight: 700;
        text-align: center;
        flex-direction: column;
        padding: 0 4px;
        transition: 0.2s ease;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 3px;
            background-color: var(--primary);
            transition: 0.2s ease;
            transform: scaleX(0);
        }

        &.right-start {
            grid-column: 3;
        }

        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }

        &:active {
            filter: brightness(0.9);

            &::after {
                filter: brightness(0.9);
            }
        }
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(4, max-content);
        justify-content: space-around;

        .nav-bar-title {
            display: none;
        }

        .nav-bar-item {
            &.right-start {
                grid-column: 1;
            }
        }
    }
}
