.copyable {
    position: relative;
    width: max-content;
    cursor: pointer;
    transition: 0.2s ease;

    .icon-wrapper {
        position: absolute;
        display: grid;
        width: 24px;
        height: 24px;
        right: -4px;
        top: 2px;
        grid-template-columns: 24px;
        grid-template-rows: 24px;
        transition: 0.4s ease;
        transform: translateX(-12px) scale(0);
        filter: opacity(0);

        .copy-icon {
            // Copy icon is optically too big, so we need to manually resize it
            font-size: 17px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            padding: 4px;
            transition: 0.4s ease;
            transform: rotate(0deg) scale(1);
            filter: opacity(1);

            &::before {
                background-color: var(--tertiaryButton);
                filter: opacity(0.1);
            }

            &:hover,
            &:focus {
                color: var(--tertiaryButton);
            }

            &.hidden {
                transform: rotate(135deg) scale(0);
                filter: opacity(0);
            }
        }

        .success-icon {
            font-size: 20px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            transform: rotate(0) scale(1);
            filter: opacity(1);
            transition: 0.4s ease;

            &.hidden {
                transform: rotate(-135deg) scale(0);
                filter: opacity(0);
            }
        }
    }

    &:hover,
    &:focus {
        .icon-wrapper {
            transform: translateX(0) scale(1);
            filter: opacity(1);
        }
    }

    &.focused {
        font-weight: bold;
        animation: 3s glow 1s;
    }

    @keyframes glow {
        10% {
            box-shadow: 0px 2px 0px var(--actionButton);
        }
        80% {
            box-shadow: 0px 2px 0px var(--actionButton);
        }
        90% {
            box-shadow: 0px 0px 0px transparent;
        }
    }
}
