@import './themes/constants.scss';

.portfolio-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    min-width: $min-content-width;
    display: flex;
    flex-direction: column;
    color: var(--textBody);
    background-color: var(--background);
    transition: 0.2s ease;
    overflow-x: hidden;
    overflow-y: visible;
}
