.portfolio-section {
    &.footer-section {
        position: relative;
        padding: 20px 80px;
        overflow: hidden;
        background-color: var(--backgroundTertiary);

        .section-content {
            display: grid;
            width: 100%;
            min-height: auto;
            grid-template-columns: max-content 1fr;
            padding: 20px 0;
            column-gap: 20px;

            .external-links {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                padding: 0 80px 0 20px;
                border-right: 2px solid var(--textBody);

                .link-wrapper {
                    width: max-content;

                    .external-link {
                        display: flex;
                        align-items: center;

                        .link-image {
                            height: 28px;
                            width: 28px;
                            margin-right: 6px;
                            aspect-ratio: 1;
                        }

                        .link-label {
                            position: relative;
                            display: flex;
                            align-items: center;
                            padding: 0 2px;
                            color: var(--link);

                            .link-icon {
                                font-size: 20px;
                                margin-left: 6px;
                                transition: 0.3s ease 0.1s;
                                transform-origin: left center;
                                transform: translateX(-12px) scale(0);
                                filter: opacity(0);
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 2px;
                                left: 0;
                                bottom: -3px;
                                background-color: var(--link);
                                transition: 0.3s ease 0.15s;
                                transform-origin: left center;
                                transform: scaleX(0);
                            }
                        }
                    }

                    &:hover,
                    &:focus {
                        .link-label {
                            .link-icon {
                                transform: translateX(0) scale(1);
                                filter: opacity(1);
                            }

                            &::after {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }

            .contact-info {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                padding: 0 20px 0;

                .info-pair {
                    height: 28px;
                    display: flex;
                    width: max-content;
                    align-items: center;

                    .info-icon {
                        font-size: 24px;
                        color: var(--tertiaryButton);
                    }

                    .info-value {
                        margin-left: 12px;
                        transition: 0.2s ease;
                    }
                }

                .copyable {
                    &:hover,
                    &:focus,
                    &:focus-within {
                        .info-pair {
                            .info-value {
                                margin-right: 24px;
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 1490px) {
                .as-h3 {
                    width: 100%;
                }

                .external-links {
                    flex-direction: row;
                    flex-wrap: wrap;
                    column-gap: 12px;
                }

                .contact-info {
                    flex-direction: row;
                    flex-wrap: wrap;
                    column-gap: 24px;

                    .info-pair {
                        .info-value {
                            margin-left: 6px;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            padding: 20px;

            .section-content {
                grid-template-columns: 1fr;
                row-gap: 20px;

                .external-links {
                    padding-bottom: 40px;
                    padding-right: 20px;
                    border-right: none;
                    border-bottom: 2px solid var(--textBody);
                }
            }
        }
    }
}
