@import '../../../themes/constants.scss';

.skills-section {
    max-height: none;
    padding: 80px;
    overflow: hidden;

    .section-content {
        display: grid;
        height: 100%;
        width: 100%;
        column-gap: 60px;

        .skills-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 1.6;

            .body-text {
                margin-top: 16px;
            }

            .skills-grid {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 40px 80px;
                margin-top: 40px;

                .skill-card {
                    width: 84px;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 0;
                    flex-shrink: 0;
                    transition: filter 0.4s ease;

                    .skill-image {
                        height: 80px;
                        margin-bottom: 8px;
                        cursor: pointer;
                        transition: 0.2s ease;

                        &:hover {
                            transform: scale(1.1);
                        }

                        &:active {
                            transform: scale(0.8);
                        }
                    }

                    .skill-title {
                        font-weight: bold;
                        text-align: center;
                    }

                    .skill-logo {
                        position: relative;
                    }

                    &.hidden {
                        filter: grayscale(1) opacity(0.5);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 950px) {
        .section-content {
            grid-template-columns: 1fr;

            .skills-text {
                grid-row: 1 / 2;

                .body-text {
                    margin: 16px 0 20px;
                }
            }

            .carousel-nav {
                width: 60%;
                justify-self: center;
            }
        }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        max-height: none;
        padding: 40px;

        .section-content {
            .skills-content {
                .filter-bar {
                    flex-wrap: wrap;
                    row-gap: 12px;
                }

                .skills-grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 40px 0;
                    margin: 40px 0 0;

                    .skill-card {
                        width: auto;

                        .skill-logo {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .skill-image {
                                height: auto;
                                width: 50%;
                                max-width: none;
                            }

                            .skill-tag {
                                bottom: 0;
                                left: 25%;

                                &:nth-child(2) {
                                    left: calc(25% + 20px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
