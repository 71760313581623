@import './themes/lightTheme.scss';
@import './themes/darkTheme.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

body * {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    margin: 0;
    box-sizing: border-box;
}

strong {
    font-weight: 600;
}

a {
    text-decoration: none;
    font-family: inherit;
    color: inherit;
}

h1,
.as-h1 {
    font-size: clamp(32px, 4vw, 64px);
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.2;
}

h2,
.as-h2 {
    font-size: clamp(16px, 2vw, 28px);
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.2;
}

h3,
.as-h3 {
    font-size: 18px;
    font-weight: bold;
    color: var(--textBody);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2;
}

.header-emoji {
    position: relative;
    bottom: clamp(0px, 0.5vw, 6px);
    font-size: clamp(22px, 3vw, 44px);
}

.body-text {
    font-size: 16px;
    font-size: clamp(14px, 2vw, 16px);
    font-weight: normal;
    line-height: clamp(140%, 2vw, 200%);
}
