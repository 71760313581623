@import '../../../themes/constants.scss';

.experience-popup {
    .popup-header {
        display: grid;
        grid-template-columns: 80px 1fr;
        column-gap: 16px;

        .header-image {
            width: 80px;
            height: 80px;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            aspect-ratio: 1;
        }

        .as-h2 {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            align-self: flex-end;
        }

        .date-range {
            font-style: italic;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
        }
    }

    // TODO: Remove
    .wip {
        min-width: 360px;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 80px 80px 40px;

        .wip-image {
            max-width: 400px;
            margin-bottom: 12px;
        }

        .wip-subtitle {
            max-width: 800px;
            margin-top: 4px;
            aspect-ratio: 866 / 683;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            min-width: auto;
            margin: 80px 20px 0;

            .wip-image {
                max-width: 80%;
                margin-bottom: 20px;
            }
        }
    }

    .scrollable-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        margin-top: 36px;
        row-gap: 36px;

        .description {
            line-height: 1.2;
        }

        .bullets {
            .bullet-list {
                margin-top: 4px;
            }
        }
    }
}
